//Fonts
@font-face {
  font-family: "Avenir Next Regular";
  src: url("./assets/fonts/AvenirNextLTPro-Regular.otf");
}

@font-face {
  font-family: "Avenir Next Bold";
  src: url("./assets/fonts/AvenirNextLTPro-Bold.otf");
}

@font-face {
  font-family: "Work Sans";
  src: url("./assets/fonts/WorkSans-Regular.ttf");
}

@font-face {
  font-family: "Work Sans Bold";
  src: url("./assets/fonts/WorkSans-Bold.ttf");
}

@font-face {
  font-family: "Work Sans Black";
  src: url("./assets/fonts/WorkSans-Black.ttf");
}

* {
  font-family: 'Avenir Next Bold';
}

.App {
  width: 100vw;
  height: 100vh;
}


.canvas {
  background-color: white;
  min-height: 100%;
  width: 100%;
}

.scape {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &-container {
    width: 100%;
    height: 100%;
  }
}

.rsvp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;

  &-image {
    width: 100%;
    height: 400px;
    background-image: url('./pages/image.jpg');
    background-size: contain;
  }

  &-input {
    width: 175px;
    height: 30px;
    border: none;
    padding-left: 10px;

    &-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      background-color: black;
      margin-left: 100px;
      margin-top: -50px;
    }

    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0rem 0.5rem;
      background-color: #9c6bc4;
      height: 30px;
      border: none;
    }

    &-passed {
      height: 30px;
      width: 30px;
      background: url('./assets/approved.png') no-repeat center;
      background-size: contain;
      margin-right: 0.5rem;
    }

    &-failed {
      height: 30px;
      width: 30px;
      background: url('./assets/denied.png') no-repeat center;
      background-size: contain;
      margin-right: 0.5rem;
    }
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  margin-right: 0.5rem;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  margin-top: 3px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


textarea:focus, input:focus{
  outline: none;
}

*:focus {
  outline: none;
}